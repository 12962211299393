import React from "react";
import card from "../assets/images/card.svg";
import { fetchJobData, parseAndSelectUrlOrNull } from "../utils/Api";

const getStatusMessage = (status, name) => {
  if (status === "SUCCEEDED" && !name) {
    return "Analysis In Progress";
  }

  switch (status) {
    case "SUBMITTED":
      return "Analysis Submitted";
    case "RUNNABLE":
      return "Analysis Pending";
    case "STARTING":
      return "Analysis Starting";
    case "RUNNING":
      return "Analysis In Progress";
    case "SUCCEEDED":
      return "Analysis Finished";
    case "FAILED":
      return "Anlysis Failed";
    default:
      return "Unknown Status";
  }
};

const handleDownload = async (jobId) => {
  if (!jobId) {
    console.error("No job ID provided.");
    return;
  }

  try {
    const data = await fetchJobData(jobId);

    if (!data || !data.fetch_url) {
      console.error("No fetch data found:", data);
      alert("Failed to download file. No URL found.");
      return;
    }

    const url = parseAndSelectUrlOrNull(data.fetch_url);

    if (!url) {
      console.error("No URL found in fetch data:", data);
      alert("Failed to download file. URL is invalid.");
      return;
    }

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up DOM
  } catch (error) {
    console.error("Error fetching job data:", error);
    alert("Failed to download file. An error occurred.");
  }
};

const renderJobUrlButton = (jobId) => {
  if (!jobId) {
    return null;
  }

  return (
    <button className="download-button" onClick={() => handleDownload(jobId)}>
      Download File
    </button>
  );
};

const jobDateToString = (date) => {
  if (!date) {
    return null;
  }

  return date.toLocaleString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

const renderStatusElement = (job) => {
  const statusMessage = getStatusMessage(job.status, job.name);

  return <p style={{ color: "white", marginTop: -5 }}>{statusMessage}</p>;
};

const Card = ({ job }) => {
  const urlButtonElem = renderJobUrlButton(job.id);
  const statusElem = renderStatusElement(job);
  const jobDateStr = jobDateToString(job.date);

  return (
    <div className="card" key={job.id}>
      <div className="div-image">
        <img alt="Results Card" className="card-image" src={card} />
      </div>
      <div className="result-text">
        <p style={{ color: "white", marginTop: -5 }}>{job.name}</p>
        {!job.name && statusElem}
        {jobDateStr && (
          <p style={{ color: "white", marginTop: -5 }}>{jobDateStr}</p>
        )}
        {job.fetch_url && urlButtonElem}
      </div>
    </div>
  );
};

export default Card;
